import React, { Component, useContext, useEffect } from 'react';
import { Context } from "../Store";
import './HeaderSectionComponent.css';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation,
    useNavigate
  } from "react-router-dom";
import * as ReactBootStrap from "react-bootstrap";

function HeaderSectionComponent() {
    useLocation()
    let navigate = useNavigate();

    const [state, dispatch] = useContext(Context);
    let user = JSON.parse(state.user);

     
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = "./js/jquery-3.3.1.min.js";
    script1.async = true;
    document.body.appendChild(script1);


    const script2 = document.createElement('script');
    script2.src = "./js/jquery-ui.min.js";
    script2.async = true;
    document.body.appendChild(script2);

    const script3 = document.createElement('script');
    script3.src = "./js/bootstrap.bundle.min.js";
    script3.async = true;
    document.body.appendChild(script3);

    // const script4 = document.createElement('script');
    // script4.src = "./js/owl.carousel.min.js";
    // script4.async = true;
    // document.body.appendChild(script4);

    // const script5 = document.createElement('script');
    // script5.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCxYLtelXg0PGjeTiFDtlN7nrH_47buDWo";
    // script5.async = true;
    // document.body.appendChild(script5);

    const script6 = document.createElement('script');
    script6.src = "./js/bootstrap-select.min.js";
    script6.async = true;
    document.body.appendChild(script6);

    const script7 = document.createElement('script');
    script7.src = "./js/daterangepicker.js";
    script7.async = true;
    document.body.appendChild(script7);

    const script8 = document.createElement('script');
    script8.src = "./js/isotope.pkgd.js";
    script8.async = true;
    document.body.appendChild(script8);

    const script9 = document.createElement('script');
    script9.src = "./js/coreNavigation-1.1.3.min.js";
    script9.async = true;
    document.body.appendChild(script9);

    const script10 = document.createElement('script');
    script10.src = "./js/scripts.js";
    script10.async = true;
    document.body.appendChild(script10);

  return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
      document.body.removeChild(script3);
    //   document.body.removeChild(script4);
      // document.body.removeChild(script5);
      document.body.removeChild(script6);
      document.body.removeChild(script7);
      document.body.removeChild(script8);
      document.body.removeChild(script9);
      document.body.removeChild(script10);

    }
  }, []);

   
    

    return (

        <header className="header">
            <nav className="">
            <div className="nav-header">
                <Link to='/'><a className="brand"><img src="./images/eco.png" id={'logo'}  alt="logo"/><span></span></a></Link>
                <button className="toggle-bar"><span className="fa fa-bars"></span></button>
            </div>
            
            <ul className="menu" style={{fontWeight: '600'}}>
                 {/* <li>
                    <a><Link to='/airtime-bundles'>Telone</Link></a>
                </li> */}
                <li>
                    <a><Link to='/airtime-bundles'>Airtime & Bundles</Link></a>
                </li>
                <li>
                     <a><Link to='/telone-Boadband'>Telone BroadBand</Link></a>
                </li>
                <li>
                     <a><Link to='/Zbc-Packages'>ZBC Licensing</Link></a>
                </li>

                {/* <li class="dropdown profile">
                <a href="#" class="profile-btn">Packages</a>
                <ul class="dropdown-menu">
                    <li class="dropdown-header bg-offwhite">
                        <Link to='/Zbc-Packages'>Zbc Packages</Link>
                    </li>
                    <li class="dropdown-header bg-offwhite">
                        <Link to='/telone-BroadBand'>Telone BroadBand</Link>
                    </li>
                    <li class="dropdown-header bg-offwhite">
                        <Link to='/Moovah-Devices'>Moovah Package</Link>
                    </li>
                </ul>
            </li> */}

                {/* <li class="dropdown profile">

                    <a href="#" class="profile-btn">Products </a>
                    <ul class="dropdown-menu ">
                        <li class="dropdown-header bg-offwhite">
                        <a><Link to='/Zbc-Packages'>Zbc Packages</Link></a>
                           
                        </li>
                        <li class="dropdown-header bg-offwhite">
                        <a><Link to='/telone-Boadband'>Telone BroadBand</Link></a>
                           
                        </li>
                       
                    </ul>
                </li> */}
      
                {/* <li>
                    <a><Link to='/telone-Boadband'>Telone BroadBand</Link></a>
                </li> */}

                {/* <li>
                    <a><Link to='/telone-Boadband'>Telone</Link></a>
                </li> */}

                {/* <li>
                <a><Link to='/dashboard'>Express Payment</Link></a>
                </li> */}
                {/* <li>
                    <a><Link to='/dashboard'>Recurring Payment</Link></a>                
                </li>
              */}
                {/* <li>
                    <a><Link to='/add-biller'>Save Favourites</Link></a>
                </li> */}
            
                {/* <li>
                    <a><Link to='/transactions'>Transactions</Link></a>
                </li> */}
                <li>
                    <a><Link to='/statements'>Wallet Statement</Link></a>
                </li>
                <li>
                    <a><Link to='/contactus'>Contact Us</Link></a>
                </li>
                
                
               
              
                        <li className="dropdown profile">
                        <Link to='/profile'> < a className="profile-btn"><i className="fa fa-user"></i></a></Link>
                            {
                           state.user != null ? 
                            <ul className="dropdown-menu left-auto profile-drop">
                                <li className="dropdown-header bg-offwhite">
                                    <div>
                                        <h5 className="hidden-xs m-b-0 text-primary text-ellipsis">{user.username}</h5>
                                        <div className="small text-muted"><span>{`Phone Number ${user.msisdn}`}</span></div>
                                    </div>
                                </li>
                                <li className="nav__create-new-profile-link">
                                    <li>
                                        <a><Link to='/profile'> <span>View personal profile</span></Link></a>
                                    </li>
                                </li>

                                {/* <li className="nav__create-new-profile-link">
                                    <li>
                                        <a><Link to='/profile'> <span>Change Password</span></Link></a>
                                    </li>
                                </li> */}

                                {/* <li className="divider"></li>
                                <li className="nav__dropdown-menu-items">
                                    <a href="profile-notifications.html">
                                        <i className="icon icon-setting"></i>
                                        <span>Settings</span>
                                    </a>
                                </li>
                                <li className="nav__dropdown-menu-items">
                                    <a onClick={() => {
                                     dispatch({
                                        type: "LOG_IN",
                                        payload: null, // The new state of the reducer
                                      });
                                      localStorage.removeItem('user');
                                      localStorage.removeItem('token');
                                      localStorage.removeItem('isLoggedIn');
                                    navigate('/')
                                }}><i className="icon icon-logout"></i><span>Logout</span></a></li> */}
                            </ul>
                            :<></>
                        }
                        </li>
                    
               
               
            </ul>
            <ul className="text-right profile-rcv">
            </ul>
        </nav>
        </header>
    )
}

export default HeaderSectionComponent;