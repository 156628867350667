import { red } from '@material-ui/core/colors';
import { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation
  } from "react-router-dom";

function PagesNavComponent(){
    useLocation();

   
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = "./js/jquery-3.3.1.min.js";
    script1.async = true;
    document.body.appendChild(script1);


    const script2 = document.createElement('script');
    script2.src = "./js/jquery-ui.min.js";
    script2.async = true;
    document.body.appendChild(script2);

    const script3 = document.createElement('script');
    script3.src = "./js/bootstrap.bundle.min.js";
    script3.async = true;
    document.body.appendChild(script3);

    // const script4 = document.createElement('script');
    // script4.src = "./js/owl.carousel.min.js";
    // script4.async = true;
    // document.body.appendChild(script4);

    // const script5 = document.createElement('script');
    // script5.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCxYLtelXg0PGjeTiFDtlN7nrH_47buDWo";
    // script5.async = true;
    // document.body.appendChild(script5);

    const script6 = document.createElement('script');
    script6.src = "./js/bootstrap-select.min.js";
    script6.async = true;
    document.body.appendChild(script6);

    const script7 = document.createElement('script');
    script7.src = "./js/daterangepicker.js";
    script7.async = true;
    document.body.appendChild(script7);

    const script8 = document.createElement('script');
    script8.src = "./js/isotope.pkgd.js";
    script8.async = true;
    document.body.appendChild(script8);

    const script9 = document.createElement('script');
    script9.src = "./js/coreNavigation-1.1.3.min.js";
    script9.async = true;
    document.body.appendChild(script9);

    const script10 = document.createElement('script');
    script10.src = "./js/scripts.js";
    script10.async = true;
    document.body.appendChild(script10);

  return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
      document.body.removeChild(script3);
    //   document.body.removeChild(script4);
      // document.body.removeChild(script5);
      document.body.removeChild(script6);
      document.body.removeChild(script7);
      document.body.removeChild(script8);
      document.body.removeChild(script9);
      document.body.removeChild(script10);

    }
  }, []);

   
    return(
        <div>
            <div className="row">
                                <div className="col-lg-12">
                                    <ul className="bill-items nav nav-tabs" id="teleporter">
                                        <li className={window.location.pathname == '/utility-bills' || window.location.pathname == '/'? `nav-item active` :  `nav-item`}><Link to='/utility-bills'><a className="nav-link"><i className="fas fa-lightbulb"></i>Utility Bills</a></Link></li>
                                        <li className={window.location.pathname == '/health-insurance' ? `nav-item active` :  `nav-item`}> <Link to='/health-insurance'><a className="nav-link"><i className="fas fa-network-wired"></i>Medical Aid</a></Link></li>
                                        <li className={window.location.pathname == '/councils' ? `nav-item active` :  `nav-item`}><Link to='/councils'><a className="nav-link"><i className="fas fa-building"></i>Councils</a></Link></li>
                                        <li className={window.location.pathname == '/life-insurance' ? `nav-item active` :  `nav-item`}>  <Link to='/life-insurance'><a className="nav-link"><i className="fas fa-users"></i>Insurance</a></Link></li>
                                        <li className={window.location.pathname == '/internet-services' ? `nav-item active` :  `nav-item`}> <Link to='/internet-services'><a className="nav-link"><i className="fas fa-broadcast-tower"></i>Internet Services</a></Link></li>
                                        <li className={window.location.pathname == '/credit-stores' ? `nav-item active` :  `nav-item`}><Link to='/credit-stores'><a className="nav-link"><i className="fas fa-building"></i>Credit Stores</a></Link></li>
                                        <li className={window.location.pathname == '/micro-finance' ? `nav-item active` :  `nav-item`}> <Link to='/micro-finance'><a className="nav-link"><i className="fas fa-credit-card"></i>Microfinance</a></Link></li>
                                        <li className={window.location.pathname == '/unversities' ? `nav-item active` :  `nav-item`}> <Link to='/unversities'><a className="nav-link"><i className="fas fa-credit-card"></i>Universities</a></Link></li>
                                        {/* <li className={window.location.pathname == '/payzimra' ? `nav-item active` :  `nav-item`}> <Link to='/payzimra'><a className="nav-link"><i className="fas fa-credit-card"></i>Pay Zimra</a></Link></li> */}
                                    </ul>
                                    <ul className="morebtn">
                                        <li>
                                            <a href="#" className="nav-link mbtn"><i className="fas fa-ellipsis-v"></i>More</a> 
                                            <ul className="dropdown-menu" id="receiver">

                                            </ul>
                                        </li>
                                    </ul>

                                </div>
             </div>
        </div>
    )
}

export default PagesNavComponent;