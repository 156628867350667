import axios from 'axios';

export class BillerService{

    state = {
        cimasEndPoint: '',
        zimraEndPOint:'',
        //othersEndPoint:'',
 }


    getAllBillers(token){
       const getData="data";
        // console.log("now in getbillers method", token);
       return  axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/biller/list`,getData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }

    getRecurringBillers(token){
        // console.log("recurring data",token);
       return  axios.post(`${process.env.REACT_APP_BACKEND_HOST_URL}/biller/recurring/list`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
    }
    getBillerAccount(token, msisdn, accountNumber, billerCode, cimasPackage, zimraTaxCode, billerName,teloneAccountType,UtandeAccountType,ZesaAccountType,currency, othersEndPoint ){

        const getData = "data";

        if (billerName == 'TelOne Postpaid' ) {
           
        if(teloneAccountType =='Landline/Account Number'){
            // teloneAccountType = 'accountNumberOrLandline';
            // console.log('teloneeeeepackage',teloneAccountType);
        }
       
        if(teloneAccountType == 'Virtual Account Number'){
             teloneAccountType = 'virtualAccountNumber';
            // console.log('teloneeeeeeepackage',teloneAccountType);

        }
        // console.log('teloneeeeeeeepackage',teloneAccountType);

        //console.log("cimaspackaaaaaaaaage",cimasPackage),

            othersEndPoint =`${process.env.REACT_APP_BACKEND_HOST_URL}/biller/find-biller-account/${billerCode}/${teloneAccountType}/${accountNumber}/${msisdn}`
        }

        if (billerName == 'ZESA PostPaid' ) {
           
    
                othersEndPoint =`${process.env.REACT_APP_BACKEND_HOST_URL}/biller/find-biller-account/${billerCode}/${ZesaAccountType}/${accountNumber}/${msisdn}`
            }


            if( billerName === 'Utande'){
                        if(currency === 'ZiG'){
                            currency = 'ZiG';
                           }
                       // this.setState((state) => (state.UtandeAccountType = `${localStorage.getItem("currency")} + &Utande`));
                       
                        UtandeAccountType = `${currency}&Utande`;
                       
                        // console.log('Utande accccccccount', UtandeAccountType);
                        othersEndPoint =`${process.env.REACT_APP_BACKEND_HOST_URL}/biller/find-biller-account/${billerCode}/${UtandeAccountType}/${accountNumber}/${msisdn}`;
                       // console.log('Utandeeeeeee',othersEndPoint);
            }


            
            if( billerName === 'Dandemutande'){
                if(currency === 'ZiG'){
                    currency = 'ZiG';
                   }
               // this.setState((state) => (state.UtandeAccountType = `${localStorage.getItem("currency")} + &Utande`));
               
                UtandeAccountType = `${currency}&Dande`;
               
                // console.log('Utande accccccccount', UtandeAccountType);
                othersEndPoint =`${process.env.REACT_APP_BACKEND_HOST_URL}/biller/find-biller-account/${billerCode}/${UtandeAccountType}/${accountNumber}/${msisdn}`;
               // console.log('Utandeeeeeee',othersEndPoint);
    }

        if ( billerName == 'CIMAS') {
           
    
                othersEndPoint =`${process.env.REACT_APP_BACKEND_HOST_URL}/biller/find-biller-account/${billerCode}/${cimasPackage}/${accountNumber}/${msisdn}`;
                // console.log('otherpoint',othersEndPoint);
            }
    


        // if ( billerName == 'ZIMRA' ) {
        //     othersEndPoint =`${process.env.REACT_APP_BACKEND_HOST_URL}/biller/find-biller-account/${billerCode}/${zimraTaxCode}/${accountNumber}/${msisdn}`
        // }

        if(billerName != 'TelOne Postpaid' && billerName != 'cimas' && billerName != 'ZIMRA' && billerName != 'Utande' && billerName != 'Dandemutande' && billerName != 'ZESA PostPaid'){
            othersEndPoint = `${process.env.REACT_APP_BACKEND_HOST_URL}/biller/find-biller-account/${billerCode}/${accountNumber}/${msisdn}`
        };

       othersEndPoint = `${process.env.REACT_APP_BACKEND_HOST_URL}/biller/find-biller-account/${billerCode}/${accountNumber}/${msisdn}`

        // console.log("otherendpoint",othersEndPoint);
        return axios.post(
            othersEndPoint,getData,
            {
            headers: {
                Authorization:
                `Bearer ${token}`,
            },
            }
        )
    }

    getAccountBalanceCharge(token, balDto){
        const options = {
        headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        },
        };
        
        
        return axios.post( `${process.env.REACT_APP_BACKEND_HOST_URL}/payment/balance-Enquiry-charge`, balDto, options);
        }


    getEpgBillerAccount(token, msisdn, accountNumber, PartnerCode, cimasPackage, zimraTaxCode, billerName,teloneAccountType,UtandeAccountType,ZesaAccountType,currency, othersEndPoint ){
         console.log("otherendpoint",msisdn);
         console.log("otherendpoint",accountNumber);
         console.log("otherendpoint",PartnerCode);
        //  console.log("otherendpoint",msisdn);

            const getData = "data";
    
           
               
           othersEndPoint = `${process.env.REACT_APP_BACKEND_HOST_URL}/epg/biller/find-biller-account/${PartnerCode}/0/${accountNumber}/${msisdn}`
    
            // console.log("otherendpoint",othersEndPoint);
            return axios.get(
                othersEndPoint,
                {
                headers: {
                    Authorization:
                    `Bearer ${token}`,
                },
                }
            );
        }
    


        getAccountBalanceAndCharge(token, balDto){
            const options = {
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            },
            };
            
            
            return axios.post( `${process.env.REACT_APP_BACKEND_HOST_URL}/biller/getbalance/withcharge`, balDto, options);
            }
}