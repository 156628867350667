import React, {Component} from "react";
import {Nav, Navbar, Container, NavDropdown} from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from "react-router-dom";
import Councils from "./Councils";
import FAQs from "./FAQs";
import Helpdesk from "./Helpdesk";
import Home from "./Home";
import Insurance from "./Insurance";
import Merchants from "./Merchants";
import MicroFinance from "./MicroFinance";
import RecurringPayments from "./RecurringPayments";
import Schools from "./Schools";
import Transactions from "./Transactions";
import Unversities from "./Unversities";
import Utilities from "./Utilities";
import logo from "../images/ecocash.png";
import paymentsLogo from "../images/paymentsPortal.png"
import { Button } from 'primereact/button';
import HealthInsurance from "./HealthInsurance";
import SignIn from "./SignIn";
import { Dock } from 'primereact/dock';
import finder from '../images/tw1.svg';
import appstore from '../images/whatsapp.svg';
import photos from '../images/fb.svg';
import trash from '../images/tg.svg';
import em from '../images/em.png';
import call from '../images/call.png';
import SignUp from "./SignUp";


export default class NavbarComponent extends Component{
    render(){

        const imgErrorPath = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png';
        const items = [
            {
                label: 'Whatsapp',
                icon: () => <img alt="Whatsapp" src={finder} onError={(e) => e.target.src = imgErrorPath} width="100%" />
            },
            {
                label: 'App Store',
                icon: () => <img alt="App Store" src={appstore} onError={(e) => e.target.src = imgErrorPath} width="100%" />
            },
            {
                label: 'Photos',
                icon: () => <img alt="Photos" src={photos} onError={(e) => e.target.src = imgErrorPath} width="100%" />
            },
            {
                label: 'Trash',
                icon: () => <img alt="trash" src={trash} onError={(e) => e.target.src = imgErrorPath} width="100%" />
            },
            {
                label: 'Email',
                icon: () => <img alt="em" src={em} onError={(e) => e.target.src = imgErrorPath} width="100%" />
            },
            {
                label: 'Call',
                icon: () => <img alt="call" src={call} onError={(e) => e.target.src = imgErrorPath} width="100%" />
            }
        ];

        return (
            <div>
              <Dock model={items} position="right" />
            <div>
            <div className="row" id="headerRow">
                <div className="col-5">
                    <img src={logo} width="30%"/>
                </div>
                {/* <div className="col-5">
                    <img src={paymentsLogo} width="60%"/>
                </div>
                <div className="col-2 pull pull-right" style={{marginTop:"40px"}}>
                    <Button icon="pi pi-facebook" className="p-button-rounded p-button-outlined" />&nbsp;
                    <Button icon="pi pi-youtube" className="p-button-rounded p-button-secondary p-button-outlined" />&nbsp;
                    <Button icon="pi pi-twitter" className="p-button-rounded p-button-success p-button-outlined" />&nbsp;
                    <Button icon="pi pi-whatsapp" className="p-button-rounded p-button-info p-button-outlined" />&nbsp;
                </div> */}
            </div>
            <div>
                <Navbar bg="primary" variant={"dark"} expand="sm">
                    <Container fluid>
                        <Navbar.Brand href="#">Ecocash Online Payments</Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <Nav.Link as={Link} to="/">Pay Bill</Nav.Link>
                            {/* <NavDropdown title="Insurance" id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/insurance">Life Insurance</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/health-insurance">Health Insurance</NavDropdown.Item>
                            </NavDropdown> */}
                            {/* { localStorage.getItem('isLoggedIn') ? <Nav.Link as={Link} to="/councils">Councils</Nav.Link>: null} */}
                            <Nav.Link as={Link} to="/councils">Express Payment</Nav.Link>
                            <Nav.Link as={Link} to="/micro-finance">Micro Finance</Nav.Link>
                            <Nav.Link as={Link} to="/schools">Save Favourates</Nav.Link>
                            <Nav.Link as={Link} to="/universities">Universities</Nav.Link>
                            <Nav.Link as={Link} to="/utilities">Recurring Payments</Nav.Link>
                            {/* <Nav.Link as={Link} to="/merchants">Merchants</Nav.Link>
                            <Nav.Link as={Link} to="/recurring-payments">Recurring Payments</Nav.Link>
                            <Nav.Link as={Link} to="/transactions">Transactions</Nav.Link>
                            <Nav.Link as={Link} to="/helpdesk">Helpdesk</Nav.Link>
                            <Nav.Link as={Link} to="/faqs">FAQs</Nav.Link> */}
                        </Nav>

                        <Nav>
                            <Nav.Link as={Link} to="/sign-in"><Button label="sign in" icon="pi pi-user"  className="p-button-success" /></Nav.Link>
                        </Nav>

                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                </div>
            </div>
            <div>
                
            <Routes>
                <Route path="/councils" element={<Councils/>}/>
                <Route path="/micro-finance" element={<MicroFinance/>}/>
                <Route path="/merchants" element={<Merchants/>}/>
                <Route path="/utilities" element={<Utilities/>}/>
                <Route path="/helpdesk" element={<Helpdesk/>}/>
                <Route path="/insurance" element={<Insurance/>}/>
                <Route path="/health-insurance" element={<HealthInsurance/>}/>
                <Route path="/schools" element={<Schools/>}/>
                <Route path="/universities" element={<Unversities/>}/>
                <Route path="/faqs" element={<FAQs/>}/>
                <Route path="/recurring-payments" element={<RecurringPayments/>}/>
                <Route path="/transactions" element={<Transactions/>}/>
                <Route path="/sign-in" element={<SignIn/>}/>
                <Route path="sign-up" element={<SignUp/>}/>
                <Route exact path="/" element={<Home/>}/>

                </Routes>
            </div>
            </div>
        )
    }
}