import React, {Component, useContext} from 'react';
import Switch from "react-switch";
import Merchants from './Merchants';
import ToggleSwitch from './utils/ToggleSwitch';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation,
    Navigate,
    useNavigate
} from "react-router-dom";
import OnlinePayements from './OnlinePayements';
import HeaderSectionComponent from './utils/HeaderSectionComponent';
import FooterComponent from './utils/FooterComponent';
import SignUp from './SignUp';
import SignIn from './SignIn';
import UserDashboard from './UserDashboard';
import Transactions from './Transactions';
import Councils from './Councils';
import HealthInsurance from './HealthInsurance';
import Insurance from './Insurance';
import MicroFinance from './MicroFinance';
import Utilities from './Utilities';
import AddFavouriteBiller from './AddFavouriteBiller';
import {Context} from "./Store";
import NSignIn from './NSignIn';
import Contact from './Contact';
import CreditStores from './CreditStores';
import InternetServices from './InternetServices';
import WelcomeComponent from './WelcomeComponent';
import WalletStatement from "./WalletStatement";
import Airtime from "./Airtime";
import AirtimeBundles from "./AirtimeBundles";
import Profile from './Profile';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';
import BulkAirtimeBundles from './BulkAirTimeBundles';
import Unversities from './Unversities';
import PayZimra from './PayZimra';
import TeloneBroadband from './TeloneBroadband';
import CmsAdminDashboard from './admin/cmsAdminDashboard';
import UploadBanner from './admin/uploadBanner';
import CmsSignIn from './admin/cmsSignIn';
import ChannelUsersBulkAirtimeBundles from './ChannelUsersBulkAirtimeAndBundles';
import ZbcPakages from './ZbcPakages';
import AcceptUserUpdateDetails from './AcceptUserUpdateDetails';
import MoovahDevice from './MoovahDevice';
// import Zimra from './Zimra';
// import ChannelUsersBulkAirtimeBundles from './channelUsers/BulkAirTimeBundles';

function NWrapper() {
    const [state, dispatch] = useContext(Context);
    let user = JSON.parse(state.user);
    let navigate = useNavigate();

    return (
        <div>
            <div className="header-top-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="contact-info">
                                <p>Contact Centre: <i className="ti-mobile"></i><a href="tel:+263114">114</a></p>
                                <p><i className="ti-email"></i> <a
                                    href="mailto:ecocashhelpdesk@ecocashholdings.co.zw">ecocashhelpdesk@ecocashholdings.co.zw</a>
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 text-md-right">
                            <ul className="login-area">
                                <li><ToggleSwitch/></li>
                                {user === null ?
                                    <React.Fragment>

                                        <li><Link to='/sign-in'><a className="login"><i
                                            className="ti-power-off"></i> Login</a></Link></li>
                                        <li><Link to='/sign-up'><a className="rsgiter"><i
                                            className="ti-plus"></i> Register</a></Link></li>
                                    </React.Fragment>
                                    :
                                    (<li><Link to='/' onClick={() => {
                                        dispatch({
                                            type: "LOG_IN", // The name of the reducer
                                            payload: null, // The new state of the reducer
                                        });
                                        localStorage.removeItem('user');
                                        localStorage.removeItem('token');
                                        localStorage.removeItem('isLoggedIn');
                                        navigate('/')
                                    }}><a className="logout"><i className="ti-power-off"></i> Logout</a></Link></li>)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <HeaderSectionComponent/>

            <Routes>
                <Route path="/health-insurance" element={<HealthInsurance/>}/>
                <Route path="/councils" element={<Councils/>}/>
                <Route path="/sign-up" element={<SignUp/>}/>
                <Route path="/sign-in" element={<NSignIn/>}/>
                
                <Route path="/accept-user-update-details" element={<AcceptUserUpdateDetails/>}>
                    {/* <Route path=":token" element={<AcceptUserUpdateDetails/>}/> */}
                    <Route path=":encryptedDetails" element={<AcceptUserUpdateDetails/>}/>
                    <Route path=":id" element={<AcceptUserUpdateDetails/>}/>
                </Route>

                <Route path="/change-password" element={<ChangePassword/>}>
                    <Route path=":token" element={<ChangePassword/>}/>
                </Route>
                <Route path="/forgot-password" element={<ForgotPassword/>}/>
                <Route path="/life-insurance" element={<Insurance/>}/>
                <Route path="/micro-finance" element={<MicroFinance/>}/>
                <Route path="/utility-bills" element={<Utilities/>}/>
                <Route path="/unversities" element={<Unversities/>}/>
                {/* .<Route path="/zimra" element={<Zimra/>}/> */}
                <Route path="/payzimra" element={<PayZimra/>}/>
                <Route path="/contactus" element={<Contact/>}/>
                <Route path="/credit-stores" element={<CreditStores/>}/>
                <Route path="/internet-services" element={<InternetServices/>}/>
                <Route path="/welcome" element={<WelcomeComponent/>}/>
                <Route path="/airtime-bundles" element={<AirtimeBundles/>}/>
                <Route path="/bulk-airtime-bundles" element={<BulkAirtimeBundles/>}/>
                <Route path="/channel-users-bulk-airtime-bundles" element={<ChannelUsersBulkAirtimeBundles/>}/>
                {/* <Route path="/channel-users-bulk-airtime-bundles" element={<ChannelUsersBulkAirtimeBundles/>}/> */}
                <Route exact path="/" element={<Utilities/>}/>
                <Route path="/telone-Boadband" element={<TeloneBroadband/>}/>
                <Route path="/Zbc-Packages" element={<ZbcPakages/>}/>
                <Route path="/Moovah-Devices" element={<MoovahDevice/>}/>

                {/* routes for CMSADMIN */}

                <Route path="cms-admin-dashboard" element={<CmsAdminDashboard/>}/>
                <Route path="upload-banner" element={<UploadBanner/>}/>
                <Route path="cms-admin-signin" element={<CmsSignIn/>}/>

                <Route
                    path="/add-biller"
                    replace
                    element={
                        user == null ? (
                            <Navigate to="/sign-in"/>
                        ) : (
                            <AddFavouriteBiller/>
                        )
                    }
                />
                <Route
                    path="/add-biller"
                    replace
                    element={
                        user == null ? (
                            <Navigate to="/sign-in"/>
                        ) : (
                            <Profile/>
                        )
                    }
                />

                <Route path="/dashboard"
                       replace
                       element={
                           user == null ? (
                               <Navigate to="/sign-in"/>
                           ) : (
                               <UserDashboard/>
                           )
                       }/>

                <Route path="/transactions"
                       replace
                       element={
                           user == null ? (
                               <Navigate to="/sign-in"/>
                           ) : (
                               <Transactions/>
                           )
                       }
                />

                <Route path="/statements"
                       replace
                       element={
                           user == null ? (
                               <Navigate to="/sign-in"/>
                           ) : (
                               <WalletStatement/>
                           )
                       }
                />

                <Route path="/profile"
                                    replace
                                    element={
                                        user == null ? (
                                            <Navigate to="/sign-in"/>
                                        ) : (
                                            <Profile/>
                                        )
                                    }
                                />

            </Routes>


            <section>
                <FooterComponent/>
            </section>

        </div>
    );
}


export default NWrapper;