import React, {Component, useState} from 'react';
//import { Context } from "./Store";
import "aos/dist/aos.css";
import {Dialog} from 'primereact/dialog';
import {PaymentService} from '../services/PaymentService';
import {ProgressSpinner} from 'primereact/progressspinner';
import ReactPolling from "react-polling";
import {Toast} from 'primereact/toast';
import {RadioButton} from 'primereact/radiobutton';
import {UserService} from '../services/UserService';
import {SubscriberAccountsService} from '../services/SubscriberAccountsService';
import {BillerCategoryService} from '../services/BillerCategoryService';
import {BillerTransactionService} from '../services/BillerTransactionService';
import {AuthService} from '../services/AuthService';
import {BillerService} from '../services/BillerService';
import {confirmPopup} from 'primereact/confirmpopup';
import {Button} from 'primereact/button';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dropdown} from 'primereact/dropdown';
import {Carousel} from 'primereact/carousel';
import { useRef } from 'react';


import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CarouselComponent  from './utils/CarouselComponent';
import ServiceProvidersComponent from './utils/ServiceProvidersComponent';
import WhatWeDoComponent from './utils/WhatWeDoComponent';
import BePartner from './utils/BePartner';
import {BillerPackageService} from '../services/BillerPackageService';
import PagesNavComponent from './utils/PagesNavComponent';
import TransactionDetails from './TransactionDetails';
import Select from 'react-select';
import CryptoJS from 'crypto-js';
// import log from '../images/payment-1.png';

// import log from '../images/eco.png';

import fml from '../images/doves.png';
import AdvertBanner from './utils/AdvertBanner';

import ToggleSwitch from './utils/ToggleSwitch';
import { BillerManagerLandPage, GetBalance, ProcessPayment, SignIn } from './facebookPixelEvent';
import axios from 'axios';
import { DecryptionService } from '../services/DecryptionService';

import _ from 'lodash';
// import { EncryptComponent } from '../services/encryption';

import config from '../components/utils/config';

import sjcl from 'sjcl';

const key2 = config.encryptionkey2;
const iv = config.encryptioniv;
const secret =config.secret;






const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
const regions = ['Bulawayo','Harare','Mashonaland East','Mashonaland West','Mashonaland Central','Masvingo','Matabeleland North','Matabeleland South','Midlands','Manicaland'];
const tax =['Customs Taxes','Domestic Taxes',]

const array = [
    { Bulawayo: ['kombo','tell'], Midlands:['res','sef']},
  ];
  


export default class OnlinePayements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBiller: null,
            billers: null,
            token: null,
            displayableBillers: null,
            msisdn: '',
            accountNumber: '',
            amount: '',
            sourceReference: '',
            isProcessingPayment: false,
            details: [],
            isShowingPaymentDetailsDialog: false,
            position: 'bottom',
            balance: 0,
            accountName: '',
            isShowingAccountDetailsDialog: false,
            isGettingBalance: false,
            selectBillerPackages: [],
            selectBillerPackage: null,
            isShowingPaymentTransactionDetailDialog: false,
            transactionDetails: null,
            isShowingTermsAndConditionsDialog: false,
            currency: '',
            disableProcessPayment: false,
            getBalanceReference: '',
            headers: [],
            getBalanceMessage:'',
            zimraTaxcode:'',
            getBalanceSelectedBiller:'',
            region:'',
            zimraOfficesList:[],
            selectedZimraOffice:'',
            zimraSelectedRegion:'',
            teloneAccountType:'',
            UtandeAccountType:null,
            amountLessThanlegacyDebtZinwa: '',
            currency: '',
            zesaAccountType:'',
            UtandereferenceId:'',
            zesaPackageCode:'',
            selectedBillerPackageName:'',
            selectedBillerPackageCode:'',
            zesaSetCurrency:null,
            packageCodeField:'',
            ZbcBillerNameList:[],
            ZbcBillerName:'',
            nustTransType:[],
            nustTransTypeField:'',
            toggleCurrency:'',
            MoovahMsisdn:'',
            MoovahDeviceList:[],
            pin:'',
            showProgressSpinner: false



        };

        this.textInput = React.createRef();

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '600px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '480px',
                numVisible: 1,
                numScroll: 1
            }
        ];

        this.authService = new AuthService();
        // this.encryption =  new EncryptComponent();
        this.billerService = new BillerService();
        this.billerCategory = new BillerCategoryService();
        this.paymentService = new PaymentService();
        this.userService = new UserService();
        this.billerTransaction = new BillerTransactionService();
        this.subscriberService = new SubscriberAccountsService();
        this.billerPackageService = new BillerPackageService();
        this.decryptionservice = new DecryptionService(); 

        this.paymentConfirmation = this.paymentConfirmation.bind(this);
        this.epgpaymentConfirmation = this.epgpaymentConfirmation.bind(this);
        this.accept = this.accept.bind(this);
        this.epgaccept = this.epgaccept.bind(this);
        this.encrypt = this.encrypt.bind(this);
        this.reject = this.reject.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.hangleGetBalance = this.hangleGetBalance.bind(this);
        this.handleDisplayPaymentConfirmation = this.handleDisplayPaymentConfirmation.bind(this);
        this.loadBillerPackage = this.loadBillerPackage.bind(this);
        this.billerTemplate = this.billerTemplate.bind(this);

        this.requestRegion = this.requestRegion.bind(this);
        this.requestTax = this.requestTax.bind(this);
        this.MoovahgetDevices = this.MoovahgetDevices.bind(this);



        this.handleChange = this.handleChange.bind(this);


          // Create a debounced version of the MoovahgetDevices method
          this.debouncedMoovahgetDevices = _.debounce(this.MoovahgetDevices, 300);
    }
    

    onClick(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false,
        });
    }


      GetBalance  = () => {
        window.fbq('track', 'Register',``);
    }


    




    renderFooter(name) {
        return (

            
            
            <div>
                

                <div style={{ flexDirection: "row" , justifyContent: 'space-between' }}>


                                <Button label="Cancel" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text"/>

                </div>
            </div>
        );
    }

    billerTemplate(biller) {
        return (
            <div className="custom-control custom-radio custom-control-inline" key={biller.id}>
                <label>

                    <input type="radio"
                           name="biller"
                           value={biller.id} onChange={(e) => {
                        this.setState({selectedBiller: this.state.billers.filter(biller => biller.id === e.target.value)[0]});
                        if (biller.hasPackage) {
                            this.loadBillerPackage(biller.code);
                        }
                    }}
                           checked={this.state.selectedBiller.id === biller.id}
                    />
                    <div className="card align-items-center" style={{
                        borderRadius: "15px",
                        boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                    }}>
                        <div className="card-body ">
                            <img src={biller.imageUrl} alt={biller.name}/>
                        </div>
                    </div>

                </label>
            </div>
        )
    }


    componentDidMount() {

        this.setState({toggleCurrency: localStorage.getItem("currency")});

        this.authService.getToken().then(
            response => this.setState({token: response.data.body}, () => {
                const options = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.state.token}`,
                    },
                };
                this.setState({headers: options})

                this.requestNUSTTransTypes(response.data.body);

                this.billerService.getAllBillers(this.state.token)
                    .then(response => {
                            var allPartners = response.data.body;
                            this.setState(
                                {
                                    billers: allPartners.filter(partner => partner.billerCategory.id == this.props.category),
                                    selectedBiller: allPartners.filter(partner => partner.billerCategory.id == this.props.category)[0]
                                });
                        }
                    )
                    .catch(error => console.log(error));

            })
        ).catch(error => {
            console.log(error)
        });





        BillerManagerLandPage();



        
    } 

    epgpaymentConfirmation(event) {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: this.epgaccept,
            reject: this.reject
        });

        
    }

    paymentConfirmation(event) {
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            accept: this.accept,
            reject: this.reject
        });

        
    }
   
   

    requestNUSTTransTypes= async (token) =>{
        console.log(token,"token");
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_HOST_URL}/nust/find-all-payment-types`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            
            const data = await response.json();
            console.error('data:', data.body);
            
            this.setState({ nustTransType: data.body });
          } catch (error) {
            console.error('Error fetching data:', error);
          }

    }


    hangleGetBalance() {
        // console.log('Utande accccccccount',  localStorage.getItem("currency"));


        if (this.state.selectedBiller.name == 'TelOne Postpaid' ) {
           
            if(this.state.teloneAccountType =='Landline/Account Number'){
                this.state.packageCodeField = 'A';
                // console.log('package', this.state.packageCodeField);
            }
           
            if(this.state.teloneAccountType == 'Virtual Account Number'){
                this.state.packageCodeField = 'V';
                // console.log('package',this.state.packageCodeField);
    
            }
        }


      
        if(this.state.selectedBiller.name ==='Utande'){
            var currency = localStorage.getItem("currency");
            
            if(currency === 'ZWG'){
                this.state.packageCodeField ='ZWG&Utande';
                // console.log('currency',  this.state.packageCodeField);
              
                
            }
            if(currency === 'USD'){
              
                this.state.packageCodeField ='USD&Utande';
                // console.log('currency',  this.state.packageCodeField);
              
            }
            
        
            
            
        }


        if(this.state.selectedBiller.name ==='Dandemutande'){
            var currency = localStorage.getItem("currency");
            
            if(currency === 'ZWG'){
                this.state.packageCodeField ='ZWG&Dande';
                //console.log('currency',  this.state.packageCodeField);
             
                
            }
            if(currency === 'USD'){
              
                this.state.packageCodeField ='USD&Dande';
                //console.log('currency',  this.state.packageCodeField);
              
            }
            
        
            
        }
    
        let balDto = {
            "msisdn": this.state.msisdn,
            "billerCode": this.state.selectedBiller.code,
            "packageCode":  this.state.packageCodeField,
            "accountNumber": this.state.accountNumber,
        }
        // console.log('balDto',balDto);

        this.authService.getGuestUserTokenWithMsisdn(this.state.msisdn).then(
    
            response => this.setState({token: response.data.body}, () => {
                // console.log(this.state.token);


                this.billerService.getAccountBalanceAndCharge(this.state.token, balDto)
                .then(response => {
                    if(response.data.body==null){
                        this.setState({getBalanceMessage:response.data.message}) 
                    }
                    this.setState({getBalanceReference: response.data.body.referenceNumber, isGettingBalance: true,});
                    this.setState({getBalanceSelectedBiller:this.state.selectedBiller.name});
                    // console.log('biller',this.state.selectedBiller.name);
                    window.fbq('track', this.state.selectedBiller.name , {Getbalance:'GetBalance'} );
                    
                })
                .catch(error => {
                    this.toast.show({
                        severity: 'error',
                        summary: 'Could not get account balance.',
                        detail: this.state.getBalanceMessage == '' ? 'Could not get account balance. Please try again later':this.state.getBalanceMessage,
                        life: 3000
                    });
    
                })


            }));
        

        // this.billerService.getAccountBalanceAndCharge(this.state.token, balDto)
        //     .then(response => {
        //         if(response.data.body==null){
        //             this.setState({getBalanceMessage:response.data.message}) 
        //         }
        //         this.setState({getBalanceReference: response.data.body.referenceNumber, isGettingBalance: true,});
        //         this.setState({getBalanceSelectedBiller:this.state.selectedBiller.name});
        //         console.log('biller',this.state.selectedBiller.name);
        //         window.fbq('track', this.state.selectedBiller.name , {Getbalance:'GetBalance'} );
                
        //     })
        //     .catch(error => {
        //         this.toast.show({
        //             severity: 'error',
        //             summary: 'Could not get account balance.',
        //             detail: this.state.getBalanceMessage == '' ? 'Could not get account balance. Please try again later':this.state.getBalanceMessage,
        //             life: 3000
        //         });

        //     })


    }

    handleDisplayPaymentConfirmation() {
 //to be uncommented for epg
        // if (!this.state.pin) {
        //     this.toast.show({
        //                     severity: 'error',
        //                     summary: 'pin',
        //                     detail: '"Please enter your PIN',
        //                     life: 3000
        //                 }); // Notify the user to input the PIN
        //     return;
        // }


this.authService.getGuestUserTokenWithMsisdn(this.state.msisdn).then(
    
    response => this.setState({token: response.data.body}, () => {
     console.log(this.state.selectedBiller.cpgVendorCode);
     // to be changed to epg service and also billercode to cpgvendorcode
        this.billerService.getEpgBillerAccount(this.state.token, this.state.msisdn, this.state.accountNumber, this.state.selectedBiller.cpgVendorCode, this.state.selectBillerPackage, this.state.zimraTaxcode,this.state.selectedBiller.name,this.state.teloneAccountType,this.state.UtandeAccountType,this.state.zesaAccountType,localStorage.getItem("currency"),)
            .then(response => {
                 console.log('booooooooody',response.data.body);
                // console.log("currrencyyyyyy" , response.data.body.currency);
                // console.log("localcurrrencyyyyyy" , localStorage.getItem("currency"));
                // console.log(this.state.accountNumber);
                // console.log('utande ref' ,response.data.body.additionalField2);


                if (this.state.selectedBiller.code == '26423' && this.state.selectedBiller.code == '72297') {

                    response.data.body.currency = localStorage.getItem("currency");
                };
                // console.log('updated currency', response.data.body.currency);
                
                var disableProcessPayment = false;  

                if (this.state.selectedBiller.name== "Kumusha Power" ) {

                    response.data.body.currency = localStorage.getItem("currency");
                    if (response.data.body.currency == "ZWG"){
                        disableProcessPayment = true;
                    }

                };

                if (this.state.selectedBiller.name === "Dandemutande" ) {
                    this.setState({UtandereferenceId:response.data.body.additionalField2});
                    // console.log('refereid',this.state.UtandereferenceId);

                    response.data.body.currency = localStorage.getItem("currency");
                    // if (response.data.body.currency == "ZWG"){
                    //     disableProcessPayment = true;
                    // }

                };

                if (this.state.selectedBiller.name === "Utande" ) {
                    this.setState({UtandereferenceId:response.data.body.additionalField2});
                    // console.log('refereid',this.state.UtandereferenceId);

                    response.data.body.currency = localStorage.getItem("currency");

                };

 



               

                if ( this.state.selectedBiller.name != 'ZIMRA' && this.state.selectedBiller.name == 'Fildelity Life Assurance' ) {
                   disableProcessPayment = response.data.body.currency == localStorage.getItem("currency") ? false : true

                }

                if ( this.state.selectedBiller.name === 'ZimLoan') {
                    
                    disableProcessPayment = localStorage.getItem("currency") === 'ZWG'? true : false
 
                 }

                
            
                // console.log(disableProcessPayment);

                if (this.state.selectedBiller.name == 'Doves' && this.state.selectedBiller.name == 'ZIMRA') {
                    disableProcessPayment = false;
                }
                // console.log(disableProcessPayment);
                //to be change to body.accountName
                response.data.body.responseCode == '200'? this.setState({accountName: response.data.body.accountName}):this.setState({accountName: response.data.body.description})
                
                if (this.state.selectedBiller.name == 'ZIMRA' && this.state.accountName == 'BP Number not registered!') {
                    disableProcessPayment = true;
                    this.toast.show({
                        severity: 'error', 
                        summary: 'BP Number not registered!',
                        detail: 'BP Number not registered!.Please make sure you have correctly typrd your BP Number',
                        life: 3000
                    });
                }



            
                
                if (disableProcessPayment && this.state.selectedBiller.name != 'Doves' && this.state.selectedBiller.name == 'Fildelity Life Assurance' && this.state.accountName != 'BP Number not registered!' ) {
                    this.toast.show({
                        severity: 'error', 
                        summary: 'Currency Mismatch',
                        detail: 'Selected currency does not match the account currency.Please change currency',
                        life: 3000
                    });

                }

            

               
                
  
               // console.log(this.state.zimraTaxcode);

                

                window.fbq('track', this.state.selectedBiller.name,{AccountDetails:'Account Details For Process Payment'} );




                if( this.state.accountName =='Invalid Account'){
                    disableProcessPayment = true;
               }


                // console.log("checcccccccccccccck", this.state.amount );

                // console.log("checcccccccccccccck", response.data.body.additionalField1 );

                
                if (response.data.body.additionalField1 != null && this.state.selectedBiller.name == 'ZINWA Satewave' ) {
                   
           
                    if(this.state.amount <= response.data.body.additionalField1){
                        // console.log("checcccccccccccccck");
                        disableProcessPayment = true;
                        this.state.amountLessThanlegacyDebtZinwa = `Please provide an amount that is greater than your legacy debt of ${response.data.body.additionalField3}`;
                    }
                }

                if( this.state.selectedBiller.name === 'Utande' && response.data.body.currency == 'ZWG' ){

                    disableProcessPayment = true;
                    // console.log(disableProcessPayment);
                 }


                 if( this.state.selectedBiller.name === 'Utande' && response.data.body.description ==="Error account number not found"){

                    disableProcessPayment = true;
                    // console.log(disableProcessPayment);
                 }
                 if( this.state.selectedBiller.name === 'ZimLoan' && response.data.body.description ==="Error account number not found"){

                    disableProcessPayment = true;
                    // console.log(disableProcessPayment);
                 }

                 if( this.state.selectedBiller.name === 'Dandemutande' && response.data.body.description ==="Error account number not found"){

                    disableProcessPayment = true;
                    // console.log(disableProcessPayment);
                 }


                //  if( this.state.selectedBiller.name === 'City of Bulawayo'){
                //     let localcurrency = localStorage.getItem("currency");
                //     if( localcurrency === "ZWG"){
                //         localcurrency = "ZWG";
                //         console.log("######",localcurrency);
                //     }
                    
                //     disableProcessPayment = response.data.body.currency === localcurrency ? false : true;
                //     console.log(disableProcessPayment);
                //  }

               // console.log("eseeeeeee",this.state.selectedBiller.acceptsZWGPayments);
                if ( this.state.selectedBiller.acceptsZWGPayments === false) {
                  //  console.log(localStorage.getItem("currency"));
                    disableProcessPayment = localStorage.getItem("currency") === 'ZWG'? true : false
 
                 }

           
                this.setState({
                    disableProcessPayment: disableProcessPayment,
                    details: [
                        this.state.selectedBiller != null && this.state.selectedBiller.name != 'ZIMRA' ?
                        { 'name': 'Account Number', 'value': this.state.accountNumber}
                        : '',
                        this.state.selectedBiller != null && this.state.selectedBiller.name == 'TelOne Postpaid' ?
                        { 'name': 'TelOne Postpaid Account Type', 'value': this.state.teloneAccountType}
                        : '',
                        
                        this.state.selectedBiller != null && this.state.selectedBiller.name == 'ZIMRA' ?
                        { 'name': 'BP Number', 'value': this.state.accountNumber}
                        : '',

                   
                        {'name': 'Account Name', 'value': this.state.accountName},
                        

                        {'name': 'Biller Name', 'value': this.state.selectedBiller.name},
                        {'name': 'Biller Code', 'value': this.state.selectedBiller.code},
                        

                        this.state.amountLessThanlegacyDebtZinwa != null && this.state.selectedBiller.name == 'ZINWA Satewave' ?
                        { 'name': ' Currency', 'value': localStorage.getItem("currency")}
                        : {
                            'name': 'Currency',
                               'value': disableProcessPayment === true ? "Currency not supported for selected Biller" : localStorage.getItem("currency")
                           },
                       

                        this.state.amountLessThanlegacyDebtZinwa != '' && this.state.selectedBiller.name == 'ZINWA Satewave'?
                                        { 'name': 'Amount', 'value': this.state.amountLessThanlegacyDebtZinwa}
                                        : { 'name': 'Amount', 'value': this.state.amount},

                        this.state.selectedBiller != null && this.state.selectedBiller.name == 'NUST' ?
                        { 'name': 'Transaction Type', 'value': this.state.nustTransTypeField}
                        : '',
                        

                        this.state.selectedBiller != null && this.state.selectedBiller.name == 'ZIMRA' ?
                        { 'name': 'Province', 'value': this.state.zimraSelectedRegion}
                        : '',

                        this.state.selectedBiller != null && this.state.selectedBiller.name == 'ZIMRA' ?
                        { 'name': 'Office', 'value': this.state.selectedZimraOffice}
                        : '',

                        this.state.selectedBiller != null && this.state.selectedBiller.name == 'ZIMRA' ?
                        { 'name': 'Tax Code', 'value': this.state.zimraTaxcode}
                        : '',
                    ]
                });
                this.setState({isShowingPaymentDetailsDialog: true})

            })







    }),
       

);

    }


    loadBillerPackage(code) {
        this.billerPackageService.getByBillerCode(this.state.token, code)
            .then(response => {
        
                this.setState({
                    selectBillerPackages: response.data,
                    selectBillerPackage: response.data[0]
                });



            })
            .catch(error => {
                // console.log(error)
             

            });
    }

    // encrypt = async (strToEncrypt, secret) => {
    //     try {
    //         // Hash the secret key using SHA-1
    //         const encoder = new TextEncoder();
    //         const keyData = encoder.encode(secret);
    //         const keyHash = await crypto.subtle.digest('SHA-1', keyData);
    //         const keyBytes = new Uint8Array(keyHash).slice(0, 16); // Use the first 16 bytes (128 bits)
    //         const key = await crypto.subtle.importKey('raw', keyBytes, 'AES-GCM', false, ['encrypt']);

    //         // Generate a random IV
    //         const iv = crypto.getRandomValues(new Uint8Array(12)); // 12 bytes IV for GCM

    //         // Encrypt the data
    //         const encrypted = await crypto.subtle.encrypt(
    //             { name: 'AES-GCM', iv: iv, tagLength: 128 },
    //             key,
    //             encoder.encode(strToEncrypt)
    //         );

    //         // Combine IV and encrypted data
    //         const encryptedArray = new Uint8Array(encrypted);
    //         const encryptedArrayWithIv = new Uint8Array(iv.length + encryptedArray.length);
    //         encryptedArrayWithIv.set(iv);
    //         encryptedArrayWithIv.set(encryptedArray, iv.length);

    //         // Return the result as a base64 encoded string
    //         return btoa(String.fromCharCode.apply(null, encryptedArrayWithIv));
    //     } catch (error) {
    //         console.error('Encryption error:', error);
    //         throw error;
    //     }
    // };

    encrypt = (pin) => {
        try {
            // const jsonString = JSON.stringify(pin);
            // console.log('concatenatedString:', user);
            let keyHex = CryptoJS.enc.Utf8.parse(key2);
            let ivHex = CryptoJS.enc.Utf8.parse(iv);
            let messageHex = CryptoJS.enc.Utf8.parse(pin);
            
            let encrypted = CryptoJS.AES.encrypt(messageHex, keyHex, {
                iv: ivHex,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            });
        
           const finalText  = encrypted.toString();
           console.error('finalText', finalText);
            // Return the result as a base64 encoded string
            return finalText
        } catch (error) {
            console.error('Encryption error:', error);
            throw error;
        }
    };

    epgaccept = async () => {
        try {
            // Encrypt the pin
            const encrypted = await this.encrypt(this.state.pin);
            console.log("pin", encrypted);
    
            // Set the encrypted pin in the state
            this.setState({ pin: encrypted });
    
            // Log the updated pin state
            console.log("pin", this.state.pin);
    
            // Show progress spinner
            this.setState({ showProgressSpinner: true });
    
            // Ensure msisdn is correctly formatted
            let msisdn = this.state.msisdn;
            if (msisdn.startsWith('0')) {
                msisdn = msisdn.substring(1);
            }
    
            console.log(msisdn);
    
            // Prepare payment DTO
            let paymentDto = {
                partnerCode: this.state.selectedBiller.cpgVendorCode,
                accountNumber: this.state.accountNumber,
                packageCode: "activee",
                currency: localStorage.getItem("currency") == null ? "ZWG" : localStorage.getItem("currency"),
                amount: this.state.amount,
                msisdn: msisdn,
                walletId: "12",
                pin: encrypted, // Use the encrypted pin from the state
                secure: true
            };
    
            console.log("paymentDto", paymentDto);
    
            // Make the payment
            const response = await this.paymentService.EpgmakePayment(this.state.token, paymentDto);
    
            // Handle response
            // console.log("response", response.data.body.status);
            console.log("response", response);
    
            if (response.data.body.status === "200") {
                this.toast.show({
                    severity: 'info',
                    summary: 'Payment success',
                    detail: 'Your payment was successful',
                    life: 5000
                });
    
                this.setState({
                    isProcessingPayment: false,
                    showProgressSpinner: false,
                    msisdn: '',
                    accountNumber: '',
                    amount: '',
                    isShowingPaymentTransactionDetailDialog: true,
                    transactionDetails: response.data.body
                });
    
                return false;
            } else if (response.data.status === 400) {
                console.log('failed', response);
    
                this.toast.show({
                    severity: 'error',
                    summary: 'Transaction failed',
                    detail: `${response.data.message}`,
                    life: 5000
                });
    
                this.setState({ 
                    isProcessingPayment: false,
                    showProgressSpinner: false,
                    isShowingPaymentTransactionDetailDialog: false,
                });
                return false;
            }
    
            window.fbq('track', this.state.selectedBiller.name, { ProcessPayment: 'ProcessPayment' });
    
        } catch (error) {
            // console.error("Payment error", error);
            // this.toast.show({
            //     severity: 'error',
            //     summary: 'Error',
            //     detail: 'An error occurred during the payment process. Please try again later.',
            //     life: 5000
            // });
            this.setState({ isProcessingPayment: false, showProgressSpinner: false });
        }
    }
    

    accept() {

        // console.log("paytoken",this.state.token);

        if(this.state.selectedBiller.name ==='Utande'){
            var currency = localStorage.getItem("currency");
            
            if(currency === 'ZWG'){
                this.state.packageCodeField ='ZWG&Utande';
                // console.log('currency',  this.state.packageCodeField);
                
                
            }
            if(currency === 'USD'){
                
                this.state.packageCodeField ='USD&Utande';
                // console.log('currency',  this.state.packageCodeField);
            
            }
            
           
            
            
        }




        if(this.state.selectedBiller.name ==='Dandemutande'){
            var currency = localStorage.getItem("currency");
            
            if(currency === 'ZWG'){
                this.state.packageCodeField ='ZWG&Dande';
                // console.log('currency',  this.state.packageCodeField);
                
                
            }
            if(currency === 'USD'){
                
                this.state.packageCodeField ='USD&Dande';
                // console.log('currency',  this.state.packageCodeField);
               
            }
            
           
            
            
        }


        
        let paymentDto = {
            endUserId: this.state.msisdn,
            billerCode: this.state.selectedBiller.code,
            billerName: this.state.selectedBiller.name,
            amount: this.state.amount,
            accountNumber: this.state.accountNumber,
            accountName: this.state.accountName,
            packageCode: this.state.packageCodeField,
            packageName: (this.state.selectBillerPackage != null ? this.state.selectBillerPackage.packageName : null),
            taxCode: (this.state.zimraTaxcode != null ? this.state.zimraTaxcode : null),
            office: ( this.state.UtandereferenceId != null ? this.state.UtandereferenceId : null),
            transactionTypeDiscription: ( this.state.nustTransTypeField != null ? this.state.nustTransTypeField : null),
            isPinless: false,
            currency: localStorage.getItem("currency") == null ? "ZWG" : localStorage.getItem("currency"),
            teloneAccountType:  (this.state.teloneAccountType != null ? this.state.teloneAccountType : null),
            refId:this.state.UtandereferenceId
        };
     

        this.paymentService.makePayment(this.state.token, paymentDto)
            .then(response => {
               
                this.setState({
                    sourceReference: response.data.body.field3,
                    isProcessingPayment: true,
                    isShowingPaymentDetailsDialog: false
                });

                window.fbq('track', this.state.selectedBiller.name,{ProcessPayment:'ProcessPayment'} );
            })
            .catch(error => {
                // console.log(error)
                

            });
        
    };

    reject() {
        // this.toast.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };


     
    requestRegion  (e) {
        // console.log(e.target.value);
        // console.log(this.state.token);
        const RegionResponse = e.target.value;
        e.persist();
        this.setState((state) => (state.zimraSelectedRegion = e.target.value))
       // console.log('ZimraSelectedRegion' , this.state.zimraSelectedRegion);
       
        e.preventDefault();
        let data = {
            region: this.state.region, 
        }

        //console.log('region',this.state.region);


        axios.get(`${process.env.REACT_APP_BACKEND_HOST_URL}/biller/zimra/regions/${RegionResponse}`, data, {
            headers: {
             'Content-Type':  'application/json',
              'Authorization': `Basic ${this.state.token}`
            },
          }).then( res => {  
            // console.log('resp',res.data.body);

   

           this.setState({zimraOfficesList: res.data.body.map((zimraRegion, index) =>
            { return {
                      officeRegion:zimraRegion.regionName,
           } })
        });

        //    console.log('officeList',this.state.zimraOfficesList); 

        })
        .catch( () => {
        //   console.log('Message not sent');
        
        })
      }


       // Method to handle input change
        handleChange(event) {
            this.setState({ region: event.target.value });
            const msisdn = event.target.value;
            console.log("check",msisdn);
            console.log("checkeeeeeeeeeeee",this.state.region);
            this.debouncedMoovahgetDevices(msisdn);

        }

    handleSelectChange(){



    }


      MoovahgetDevices(value){
    //     const newValue = e.target.value;
    //     this.setState({ region: newValue });

    //    const text = this.debouncedMoovahgetDevices(newValue);
        // const msisdn = e.target.value;

              console.log("value",value);
        // console.log(this.state.token);
        // const msisdn = e.target.value;
        // e.persist();
        //this.setState((state) => (state.zimraSelectedRegion = e.target.value))
       // console.log('ZimraSelectedRegion' , this.state.zimraSelectedRegion);
       
        // e.preventDefault();
        // let data = {
        //     region: this.state.region, 
        // }

        //console.log('region',this.state.region);


        axios.get(`${process.env.REACT_APP_BACKEND_HOST_URL}/moovah/device/find-by-msisdn?msisdn=${value}`, {
            headers: {
             'Content-Type':  'application/json',
            },
          }).then( res => {  
            console.log('resp',res.data.body);

            this.setState({MoovahgetDevices: res.data.body});
           this.setState({MoovahgetDevices: res.data.body.map((zimraRegion, index) =>
            { return {
                      officeRegion:zimraRegion.regionName,
           } })
        });

         console.log('MoovahgetDevices',this.state.MoovahgetDevices); 

        })
        .catch( (e) => {
        console.log('Message not sent',e);
        
        })





      }

    requestTax  (e) {
        // console.log(e.target.value);
        // console.log(this.state.Offices);
        
      }
      
      
    

    render() {
        return (
            <div>
                 {/* {this.state.showProgressSpinner && (
                    <div style={{ textAlign: 'center' }}>
                        <ProgressSpinner />
                    </div>
                )} */}
                
                <Toast ref={(el) => this.toast = el}/>
                <div style={{paddingLeft: '5.3%', paddingRight: '10%', marginTop: '0%'}}>
                <section className="banner py-0" >
                    <CarouselComponent/>
                </section>
                </div>

                <section className="main-form py-0">
                    <div className="container">
                        <div className="row">
                            <PagesNavComponent/>
                        </div>

                        <div className="row">
                            <div className="col-md-12 form-area pt-5">
                                <h2 className="text-4 mb-3">Select Biller to Pay</h2>
                                <div className="mb-3">
                                    {
                                       
                                        this.state.billers != null && this.state.billers.filter(billerz => billerz.name !=="ZBC" )  ? this.state.billers.map((biller) => (  biller.name !== "ZBC" && biller.name !== "TelOne Prepaid" ?
                                                <div className="custom-control custom-radio custom-control-inline"
                                                     key={biller.id}>
                                                    <label>

                                                        <input type="radio"
                                                               name="biller"
                                                               value={biller.id} onClick={(e) => {
                                                            this.setState({selectedBiller: this.state.billers.filter(biller => biller.id == e.target.value)[0]});
                                                            if (biller.hasPackage) {
                                                                this.loadBillerPackage(biller.code);
                                                            }
                                                            this.textInput.current.focus();
                                                        }}
                                                               checked={this.state.selectedBiller.id === biller.id}
                                                        />
                                                        <div className="card align-items-center" style={{
                                                            borderRadius: "15px",
                                                            boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
                                                        }}>
                                                           {
                                                            <div className="card-body ">
                                                                <img src={biller.imageUrl} alt={biller.name}/>
                                                            </div>
    }
                                                        </div>

                                                    </label>
                                                </div>

                                            : <></>)
                                            )
                                            : <></>
                                    }

                                </div>

                               
                                <div className="form-row">
                                        <div className="col-md-6 col-lg-3 form-group">
                                        <input type="text" ref={this.textInput}
                                               className="form-control" data-bv-field="accountNumber" id="accountNumber"
                                               value={this.state.accountNumber} required
                                               placeholder={this.state.selectedBiller != null ? this.state.selectedBiller.accountNumberPromptMessage : 'Enter Account Number'}
                                               onChange={(e) => {
                                                   e.persist();
                                                   this.setState((state) => (state.accountNumber = e.target.value))
                                               }}/>
                                        </div>
                                                                   
                                    
                                        <div className="col-md-6 col-lg-3 form-group">
                                        <input type="text" className="form-control" data-bv-field="msisdn"
                                               id="mobileNumber" value={this.state.msisdn} required
                                               placeholder="Enter Mobile Number(7********)" onChange={(e) => {
                                            e.persist();
                                            this.setState((state) => (state.msisdn = e.target.value))
                                        }}/>
                                        </div>

                                        
                                       
                                      
                                        {/* To be uncommented for epg  */}
                                        <div className="col-md-6 col-lg-3 form-group">
                                            <input
                                                type="password" // Changed to "password" to hide the input for PIN
                                                className="form-control"
                                                data-bv-field="pin" // Updated to "pin"
                                                id="pin" // Updated to "pin"
                                                value={this.state.pin} // Updated to "this.state.pin"
                                                required
                                                placeholder="Enter PIN" // Updated placeholder text
                                                onChange={(e) => {
                                                    e.persist();
                                                    this.setState((state) => (state.pin = e.target.value)); // Updated to "state.pin"
                                                }}
                                            />
                                        </div>
                                 

                                    {
                                        this.state.selectedBiller != null && this.state.selectedBiller.name === 'Moovah' ?  
                                        <div className="col-md-6 col-lg-3 form-group">
                                            <input type="text"
                                             className="form-control"
                                             data-bv-field="msisdn"
                                             id="mobileNumber"
                                             placeholder="Enter Mobile Number(07********)"
                                            value={this.state.region} onChange={this.handleChange} />
                                </div>
                                            : <></>
                                    }
{/* 
                                    
                                  {
                                        this.state.selectedBiller != null && this.state.selectedBiller.name === 'Moovah' ?  
                                        <div className="col-md-6 col-lg-3 form-group">
                                           <select onChange={this.handleSelectChange}>
                                                <option value="">Select IMEI Code</option>
                                                {this.state.MoovahDeviceList.map(device => (
                                                    <option key={device.id} value={device.imeiCode}>
                                                    {device.imeiCode}
                                                    </option>
                                                ))}
                                                </select>
                                        </div>
                                            : <></>
                                    } */}




                                    {/* {
                                        this.state.selectedBiller != null && this.state.selectedBiller.name === 'ZIMRA' ?  
                                <div class="col-md-6 col-lg-3 form-group">
                                        <select onChange={(e) => {
                                                             e.persist();
                                                             this.setState((state) => (state.selectedZimraOffice = e.target.value));
                                                        }}
                                                      className="form-control"
                                                     >
                                                    <option>Select a  Zimra Office</option>
                                                    { this.state.zimraOfficesList.map((officeList) => (
                                                            <option key={officeList.id}>{officeList.officeRegion}</option>
                                                       
                                                    ))}
                                                    </select>
    
                                </div>
                                            : <></>
                                    } */}

{/* {
                                        this.state.selectedBiller != null && this.state.selectedBiller.name == 'ZIMRA' ?  
                                        <div class="col-md-6 col-lg-3 form-group">
                                        <select 
                                           InputValue={this.state.zimraTaxcode}
                                           onChange={(e) => {
                                                e.persist();
                                               this.setState((state) => (state.zimraTaxcode = e.target.value));
                                           }} 
                                           
                                                   className="form-control"
                                                   >
                                               <option>Select Tax Code</option>
                                               <option>DUTY</option>
                                               <option>PAYE</option>
                                               <option>VAT</option>
                                       </select> 

                                     </div>
                                            : <></>
                                    } */}



{
                                        this.state.selectedBiller != null && this.state.selectedBiller.name === 'NUST' ?  
                                <div class="col-md-6 col-lg-3 form-group">
                                        <select onChange={(e) => {
                                                             e.persist();
                                                             this.setState((state) => (state.nustTransTypeField = e.target.value));
                                                        }}
                                                      className="form-control"
                                                     >
                                                    <option>Select Nust Transaction Type</option>
                                                    { this.state.nustTransType.map((nust) => (
                                                            <option key={nust.id}>{nust.name}</option>
                                                       
                                                    ))}
                                                    </select>
    
                                </div>
                                            : <></>
                                    }

{
                                        this.state.selectedBiller != null && this.state.selectedBiller.name === 'ZBC' ?  
                                        <div class="col-md-6 col-lg-3 form-group">
                                        <select 
                                           InputValue={this.state.zimraTaxcode}
                                           onChange={(e) => {
                                                e.persist();
                                               this.setState((state) => (state.zimraTaxcode = e.target.value));
                                           }} 
                                           
                                                   className="form-control"
                                                   >
                                               <option>Select Product</option>
                                               <option>Vehicle license</option>
                                       </select> 

                                     </div>
                                            : <></>
                                    }   


{
                                this.state.selectedBiller != null && this.state.selectedBiller.name == 'ZESA PostPaid'?
                                            <div class="col-md-6 col-lg-3 form-group">
                                                 <select 
                                                    InputValue={this.state.zesaAccountType}
                                                    onChange={(e) => {
                                                        e.persist();
                                                       this.setState((state) => (state.zesaAccountType= e.target.value));
                                                   }} 
                                                    
                                                            className="form-control"
                                                            >
                                                        <option>Select Account Type</option>
                                                        { this.state.selectBillerPackages.map((officeList) => (
                                                            <option key={officeList.id}>{officeList.packageName}</option>
                                                       
                                                    ))}
                                                </select> 

                                            </div>
                                            : <></>
                            }




                           {
                                this.state.selectedBiller != null && this.state.selectedBiller.name === 'TelOne Postpaid'?
                                            <div class="col-md-6 col-lg-3 form-group">
                                                 <select 
                                                    InputValue={this.state.teloneAccountType}
                                                    onChange={(e) => {
                                                        e.persist();
                                                       this.setState((state) => (state.teloneAccountType = e.target.value));
                                                   }} 
                                                    
                                                            className="form-control"
                                                            >
                                                        <option>Select Account Type</option>
                                                        <option>Landline/Account Number</option>
                                                        <option>Virtual Account Number</option>
                                                </select> 

                                            </div>
                                            : <></>
                            }


                                    {
                                        this.state.selectedBiller != null && this.state.selectedBiller.hasPackage && this.state.selectedBiller.name != 'TelOne Postpaid' && this.state.selectedBiller.name != 'Dandemutande' && this.state.selectedBiller.name != 'ZESA PostPaid' ?
                                            <div class="col-md-6 col-lg-3 form-group">
                                                 <select 
                                                    InputValue={this.state.selectBillerPackage}
                                                    onChange={(e) => {
                                                   
                                                        this.setState({selectBillerPackage: e.value});
                                                    }} 
                                                    
                                                            className="form-control"
                                                            >
                                                        <option>Select a package</option>
                                                        <option>Policy Holder</option>
                                                        <option>Group Payer</option>
                                                </select> 

                                            </div>
                                            : <></>
                                    }


                                   {/* {
                        
                                        this.state.selectedBiller != null && this.state.selectedBiller.name !== 'ZESA Prepaid' && this.state.selectedBiller.name != 'Kumusha Power' && this.state.selectedBiller.name != 'ZESA PostPaid' && this.state.selectedBiller.name != 'Bulawayo City Council' ?
                                        <div className="col-md-6 col-lg-2 form-group">
                                                <button className="btn btn-default btn-block rounded-0"
                                                    onClick={this.hangleGetBalance}
                                                    disabled={this.state.isGettingBalance}>{this.state.isGettingBalance ? 'Loading...' : 'Get balance'}</button>
                                        </div>
                                            : <></>
                                    } */}

                                    


                                    <div className="col-md-6 col-lg-2">
                                        <div className="form-group input-group">
                                            <div className="input-group-prepend"><span
                                                className="input-group-text">$</span></div>
                                            <input className="form-control" id="amount" placeholder="Enter Amount" 
                                            min="0"
                                            onKeyDown={blockInvalidChar}
                                            onkeyup="if(this.value<0){this.value= this.value * -1}"

                                                   value={this.state.amount} required type="number" onChange={(e) => {
                                                e.persist();
                                                this.setState((state) => (state.amount = e.target.value))
                                            }}/>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-2 form-group">
                                        <button className="btn btn-default btn-block rounded-0"
                                                onClick={this.handleDisplayPaymentConfirmation}>Continue
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="we-work bg-offwhite">
                    <AdvertBanner/>
                </section>

                <section className="work bg-bluewhite">
                    <WhatWeDoComponent/>
                </section>

                <section className="we-work bg-offwhite">
                    <ServiceProvidersComponent/>
                </section>

                <section className="callto-action">
                    <BePartner/>
                </section>

               

                <Dialog header={<h3 class="admin-heading bg-offwhite">
                    <p>Transaction processing</p>
                    <span>Enter your ecocash pin on your phone...</span>
                </h3>
                } visible={this.state.isProcessingPayment} position={this.state.position} style={{width: '20vw'}}
                       modal
               onHide={() => this.onHide('isProcessingPayment')} breakpoints={{'960px': '75vw'}}
                >
                    
                    <ReactPolling
                        url={`${process.env.REACT_APP_BACKEND_HOST_URL}/biller-transaction/find-by-source-ref/${this.state.sourceReference}`}
                        interval={3000} 
                        retryCount={3} 
                        headers={{
                          
                            'Authorization': `Bearer ${this.state.token}`,
                        }}
                        onSuccess={(data) => {
                            if (data.status === "SUCCESS") {
                                
                                this.toast.show({
                                    severity: 'info',
                                    summary: 'Payment success',
                                    detail: 'Your payment was successful',
                                    life: 5000
                                })

                                this.setState({
                                    isProcessingPayment: false,
                                    msisdn: '',
                                    accountNumber: '',
                                    amount: '',
                                    isShowingPaymentTransactionDetailDialog: true,
                                    transactionDetails: data
                                });

                                return false;
                            } else if (data.status === "FAILED") {
                                console.log('faileddddddd',data);
                               
                                if(this.state.selectedBiller.name === 'ZINWA Satewave'){

                                    this.toast.show({
                                        severity: 'error',
                                        summary: 'Transaction failed',
                                        detail: 'payer transaction amount less than defined value',
                                        life: 7000
                                    })
    

                                }

            
                                this.toast.show({
                                    severity: 'error',
                                    summary: 'Transaction failed',
                                    detail: 'Your transaction failed. Please try again later',
                                    life: 5000
                                })

                                this.setState({isProcessingPayment: false});
                                return false;
                            }
                            return true;
                        }}
                        onFailure={() => console.log("handle failure")} 
                        render={({startPolling, stopPolling, isPolling}) => {
                            if (isPolling) {
                                return (
                                    <div className="" style={{align: "centre"}}>
                                        <ProgressSpinner/>

                                    </div>
                                );
                            } else {
                                return <></>;
                            }
                        }}
                    />
                </Dialog>

                <Dialog header={<h3 class="admin-heading bg-offwhite">
                    <p>Account Details</p>
                    <span>View account details and balance</span>
                </h3>
                }
                        visible={this.state.isShowingAccountDetailsDialog} position={this.state.position} modal
                        style={{width: '70%', height: '70%'}}
                        footer={this.renderFooter('isShowingAccountDetailsDialog')}
                        onHide={() => this.onHide('isShowingAccountDetailsDialog')} breakpoints={{'960px': '75vw'}}>
                    <div className='row' style={{paddingLeft: '10%', paddingRight: '10%', marginTop: '2%'}}>
                        <br/>

                        <div className='row'>
                            <DataTable value={this.state.details} stripedRows responsiveLayout="scroll">
                                <Column field="name" header="Name"></Column>
                                <Column field="value" header="Value"></Column>
                            </DataTable>

                        </div>


                    </div>
                </Dialog>

                <Dialog header={<h3 class="admin-heading bg-offwhite">
                    <p>Payment Details</p>
                    <span>Verify payment details</span>
                </h3>
                } visible={this.state.isShowingPaymentDetailsDialog} position={this.state.position} modal
                        style={{width: '70%', height: '80%'}}
                        footer={this.renderFooter('isShowingPaymentDetailsDialog')}
                        onHide={() => this.onHide('isShowingPaymentDetailsDialog')} breakpoints={{'960px': '75vw'}}
                        >
                    <div className='row' style={{paddingLeft: '10%', paddingRight: '10%', marginTop: '0%'}}>
                    
                        <div className='col-sm-10'style={{display: 'flex', justifyContent: 'space-between'}}>
                                <h3>Account Details</h3>
                                <button disabled={this.state.disableProcessPayment} className="btn btn-filled btn-round"
                                 // to be change to epgpaymentConfirmation
                                        onClick={this.epgpaymentConfirmation}><span className="bh"></span> <span>Process payment</span>
                                </button>

                             </div>
                        <br/>

                        <div className='row'>
                            <DataTable value={this.state.details} stripedRows responsiveLayout="scroll">
                                <Column field="name" header="Name"></Column>
                                <Column field="value" header="Value"></Column>
                            </DataTable>

                        </div>

                        <div className='row' style={{marginTop: '20px'}}>

                            <div className='col-sm-12'>
                                <button disabled={this.state.disableProcessPayment} className="btn btn-filled btn-round"
                                // to be change to epgpaymentConfirmation
                                        onClick={this.epgpaymentConfirmation}><span className="bh"></span> <span>Process payment</span>
                                </button>

                            </div>

                        </div>

                    </div>
                </Dialog>

                <Dialog header={<h3 class="admin-heading bg-offwhite">
                    <p>Transaction Details</p>
                    <span>Payment details made</span>
                </h3>
                }
                        visible={this.state.isShowingPaymentTransactionDetailDialog} position={this.state.position}
                        modal style={{width: '70%', height: '70%'}}
                        footer={this.renderFooter('isShowingPaymentTransactionDetailDialog')}
                        onHide={() => this.onHide('isShowingPaymentTransactionDetailDialog')}
                        breakpoints={{'960px': '75vw'}}>
                    <TransactionDetails tDetails={this.state.transactionDetails} biller={this.state.selectedBiller}/>
                </Dialog>


                <Dialog header={<h3 class="admin-heading bg-offwhite">
                    <p>Getting account balance</p>
                    <span>Enter your ecocash pin on your phone...</span>
                </h3>
                } visible={this.state.isGettingBalance} style={{width: '20vw', textAlign: 'center'}}
                onHide={() => this.onHide('isGettingBalance')} breakpoints={{'960px': '75vw'}}>
                    <ReactPolling
                        url={`${process.env.REACT_APP_BACKEND_HOST_URL}/charge-balance/findbysourcereference/${this.state.getBalanceReference}`}
                        interval={3000} 
                        retryCount={3}
                        headers={{
                         
                            'Authorization': `Bearer ${this.state.token}`,
                        }}
                    

                    

                        onSuccess={(data) => {
                            console.log('balance',data.body);
                            if (data.body.status == 'SUCCESS') {
                                console.log('getbalance',data.body);
                                console.log('balance.......',data.body.balance);

                                this.setState({
                                    accountName: data.body.accountName,
                                    isGettingBalance: false,
                                    details: [
                                        {'name': 'Account Name', 'value': data.body.accountName},
                                        {'name': 'Account Number', 'value': this.state.accountNumber},
                                        {'name': 'Biller Name', 'value': this.state.selectedBiller.name},
                                        {'name': 'Biller Code', 'value': this.state.selectedBiller.code},
                                        {'name': 'Currency', 'value': localStorage.getItem("currency")},
                                          this.state.selectedBiller != null && this.state.selectedBiller.name === 'ZINWA Satewave' ?
                                          { 'name': 'Remaining Volume', 'value': data.body.additionalField2}
                                          : '',

                                        this.state.selectedBiller != null && this.state.selectedBiller.name === 'ZINWA Satewave' ?
                                        { 'name': 'Remaining Money', 'value': data.body.balance} 
                                        :  { 'name': 'Balance', 'value': data.body.balance},

                                        this.state.selectedBiller != null && this.state.selectedBiller.name === 'ZINWA Satewave' ?
                                        { 'name': 'Post-paid Meter Legacy Debt', 'value': data.body.additionalField3}
                                        : '',
                                        
                                        
                                    ]
                                });

                                this.setState({isShowingAccountDetailsDialog: true, isGettingBalance: false});
                                return false;
                            } else if (data.body.status === "FAILED") {
                            
                                this.toast.show({
                                    severity: 'error',
                                    summary: 'Get account balance failed',
                                    detail: data.body.description,
                                    life: 5000
                                })

                                this.setState({isGettingBalance: false});
                                return false;
                            }
                            return true;
                        }}
                        onFailure={() => console.log("handle failure")} // this is optional
                        render={({startPolling, stopPolling, isPolling}) => {
                                 if (isPolling) {
                                return (
                                    <div className="" style={{align: "centre"}}>
                                        <ProgressSpinner/>

                                    </div>
                                );
                            } else {
                                //this.setState({isProcessingPayment:false})
                                return <></>;
                            }
                        }}
                    />
                </Dialog>

                
            </div>
        )
    }

};
